/***************************************************
Membership forms pages
***************************************************/
#form-layout {
	padding: 0;
	min-height: calc(100vh - 110px);
}

.guest-membership-layout {
	display: flex;
	flex-flow: row nowrap;
	height: 100%;
	min-height: calc(100vh - 110px);	
}

.two-third {
	padding: 3%;
	max-width: 65%;
}

.one-third {
	padding: 3%;
	// width: 35%;
}

.two-col {
	padding: 1%;
	width: 50%;
}


/***************************************************
Hotel Membership forms pages
***************************************************/
.hotel-form-layout {
	padding-top: 50px;
}

.hotel-form {
	h4 {
		margin-bottom: 1.5em;
	}

	.form-container {
		padding-top: 30px;
		padding-bottom: 30px;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		> div:first-child {
			width: 25%;
		}

		.grid-cols {
			width: 75%;

			> div {
				width: 29.333%;
				margin: 0 2%;
			}
		}
	}
}


/* Mobile portrait */
@media only screen and (max-width : 480px) {
	.guest-membership-layout {
		flex-flow: column;
	}

	.two-third {
		max-width: 100%;
		padding: 80px 3% 30px;
	}

	.two-col {
		width: 100%;
	}

	.hotel-form {
		.form-container {
			flex-flow: column nowrap;

			> div:first-child {
				width: 100%;
			}

			.grid-cols {
				width: 100%;
    		> div { 
    			width: 100%;
    			margin: 0;
    		}
			}
		}
	}
}

