.header {
	align-items: start;
	display: flex;
	flex-flow: row nowrap;
	justify-content: start;
	min-height: 54px;
	position: relative;
	transition: all .3s ease-in-out;
	width: 100%;
	z-index: 10;

	nav {
		position: relative;
		width: 33.333%;
	}

	.logo {
		text-align: center;

		a {
			color: $brandColor;
		}

		img {
			left: calc(50% - 48px);
			position: absolute;
		}
	}

	.main-menu {
		ul {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;;
			list-style: none inside;

			li {
				display: inline-block;
				line-height: 1.5;

				a {
					color: $darkGray;
					display: block;
					padding: 15px 0;
					text-align: center;

					&:hover {
						color: $brandColor;
					}
				}
			}
		}
	}
}

// hambnurger menu
.mobile-menu {
	background: transparent;
	border: 0;
	display: none;
	height: 20px;
	padding: 0;
	position: absolute;
	right: 40px;
	top: 10px;
	transition: all .3s ease-in-out;
	width: 30px;
	z-index: 10;

	span {
		background-color: $brandColor;
		border-radius: 5px;
		display: block;
		height: 4px;
		opacity: 1;
		position: absolute;
		-webkit-transform-origin: left center;
		transform-origin: left center;
		transition: all .3s ease-in-out;
		width: 100%;

		&:nth-child(1) {
			top: 0;
		}

		&:nth-child(2) {
			top: 8px;
		}

		&:nth-child(3) {
			top: 16px;
		}

	}

	&.close-nav {
		span:nth-child(2) {
			opacity: 0;
		}

		span:first-child,
		span:last-child {
			position: absolute;
		}

		span:first-child {
			top: 0;
			transform: rotate(45deg);
		}

		span:last-child {
			top: 21px;
			transform: rotate(-45deg);
		}
	}

	&:focus {
		background: none;
		border: 0;
		box-shadow: none;
		outline: none;
	}

	&:hover {
		background: none;
	}
}


/*********************************
Media Queries Desktop first
********************************/
 /* Medium Devices, ipad landscape */
@media only screen and (max-width : 1024px) {
}

/* Small Devices, tablets portrait */
@media only screen and (max-width : 992px) {
	.mobile-menu {
		display: block;
	}

	.header {
		flex-flow: column nowrap;

		nav {
			width: 100%;
		}

		.logo {
			margin-bottom: -80px;
			order: 0;

			img {
				left: auto;
				position: relative;
			}
		}

		.main-menu {			
			order: 1;
			height: 0;
			overflow: hidden;
			transition: all 0.3s ease-in-out;

			ul {
				flex-flow: column nowrap;

				li {
					display: block;
					text-align: center;
				}
			}
		}

		&.open-menu {
			.logo {
				margin-bottom: 0;
			}

			.main-menu {	
				height: auto;
				overflow: auto;
			}
		}
	}
}

/* Mobile portrait */
@media only screen and (max-width : 480px) {
	.mobile-menu {
		right: 0;
	}
}
