.modal-back {
  background: rgba(0, 0, 0, 0.6);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}


.modal {
  background: white;
  display: flex;
  flex-flow: column wrap;
  height: auto;
  margin: 10% auto 0;
  padding: 40px 20px;
  position: relative;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(.5);
  width: 40%;

  h2 {
    line-height: 1;
    margin-bottom: 20px;
    text-align: center;
  }

  p {
    font-size: 18px;
  }
}

.brgc-open {
  opacity: 1;
  transform: scale(1);
}

.modal-confirmed {
  display: inherit;
  margin: 0 auto;
  position: relative;
  width: 30%;
}

/* Mobile portrait */
@media only screen and (max-width : 480px) {
  .modal {
    width: 80%;
  }
}