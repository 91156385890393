.hotel-hdr {
	background-position: center center;
	background-size: cover;

	.back-to-results {
		position: absolute;
		top: 1%;
		left: 1%;
		z-index: 2;
	}

	.hotel-intro {
		@include box-shadow(0, 3px, 21px, rgba(0, 0, 0, 0.34));
		background: white;
    bottom: 5%;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-end;
    padding: 25px;
    position: absolute;
    right: 3%;
    visibility: hidden;
    width: 40%;
    z-index: 2;

    h1 {
    	color: $brandColor;
    	display: block;
    	letter-spacing: 0;
  		text-transform: none;    	
    	width: 100%;

    	.script-subtitle {
  			margin: 20px 0 -37px 20px;
    	}
    }

    button {
			bottom: -15px;
			position: absolute;
			right: -15px;
    }
	}
}

.rooms-title {
	text-align: center;
	visibility: hidden;
	
	.script-subtitle {
		transform: translate(-4%, -5px);
	}
}
.hotel-rooms {
	padding: 80px 0;

	.rooms-slider {
		overflow: hidden;
		visibility: hidden;
	}

	.slides {
		margin-top: 60px;
		overflow: hidden;

		.room-info {
			align-items: center;
			border-left: 1px solid $brandColor;
			border-right: 1px solid $brandColor;
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			padding: 0 5% 40px;
		}

		.description {			
			padding-right: 25px;
			width: 40%;

			ul {
				list-style: outside disc;
				margin-bottom: 60px;
			}

			li {
				line-height: 1.5;
				margin-bottom: .768em;
				margin-left: 1em;
			}
		}

		.room-img {
			@include box-shadow(0, 3px, 21px, rgba(0, 0, 0, 0.34));			
			width: 60%;
		}
	}
}

.hotel-amenities {
	// align-items: center;
	flex-flow: row wrap;
	display: flex;
	margin-top: 60px;
	padding: 20px 0 0;

	.amenities-info {
		padding: 20px 40px 0;
		width: 55%;

		ul {
			list-style: outside disc;
			margin-bottom: 60px;
		}

		li {
			line-height: 1.5;
			margin-bottom: .768em;
			margin-left: 1em;
		}
	}
	.amenities-img {
		padding-right: 40px;
		transform: translateY(-10%)!important;
		width: 45%;

		img{ 
			@include box-shadow(0, 3px, 21px, rgba(0, 0, 0, 0.34));
			width: 100%;
		}
	}
}

.hotel-contact-location {
	align-items: center;
	display: flex;
	flex-flow: row wrap;

	.contact-location {
		width: 30%;
		padding: 60px 40px;

		.rooms-title {
			text-align: left;
		}
	}

	.location-map {
		width: 70%;
		height: 60vh;
	}
}

/*********************************
Media Queries Desktop first
********************************/
 /* Medium Devices, ipad landscape */
@media only screen and (max-width : 1024px) {	
}

/* Small Devices, tablets portrait */
@media only screen and (max-width : 768px) {
	.hotel-hdr {
		height: 40vh;

		.hotel-intro {
			margin-bottom: -10%;

			h1 {
				font-size: 2em;
				.script-subtitle {
					margin: 20px 0 -23px 20px;
				}
			}
		}
	}
}

/* Mobile portrait */
@media only screen and (max-width : 480px) {
	.hotel-hdr {
		background-position: top;
		background-repeat: no-repeat;
		background-size: contain;
		height: auto;
		padding-top: 25vh;
		margin-top: 100px;

		.back-to-results {
			top: -5%;
		}

		.hotel-intro {
			box-shadow: none;
			position: relative;
			width: 100%;
			margin: 5px 0;
			padding: 25px 0;
			right: auto; 

			h1 {
				font-size: 2em;
				.script-subtitle {
					margin: 20px 0 -23px 20px;
				}
			}

			button {
				right: 50%;
    		transform: translateX(50%);
			}
		}
	}

	.hotel-rooms {
		.slides {
			.room-info, .hotel-amenities {
				flex-flow: column;
			}

			.description {
				width: 100%;
				order: 1;
				margin-top: 30px;
			}

			.room-img {
				width: 100%;
				order: 0;
			}
		}
	}

	.hotel-amenities {
		margin-top: 0;
		padding-bottom: 20px;

		.amenities-info {
			margin-top: 10px;
			order: 1;
			width: 100%;
		}
		.amenities-img {
			padding-right: 0;
			order: 0;
			width: 100%;
		}
	}

	.hotel-contact-location {
		flex-flow: column;
		padding-top: 60px;

		.contact-location {
			margin-bottom: 40px;
			padding: 0;
			width: 100%;

			.rooms-title {
				text-align: center;
			}
		}

		.location-map {
			width: 100%;
		}
	}
}