// html,body {
// 	height: 100vh;
// 	position: relative;
// 	width: 100vw;
// }

body {
	padding: 25px;
}

.stage {	
	min-height: calc(100% - 60px);
	padding-top: 60px;
	position: relative;
	width: 100%;
	z-index: 1;
}

.fixed-stage {
	height: calc(100vh - 110px);
}

.container {
	display: block;
	margin: 0 2%;
	position: relative;
}

.bordered-stage {
	border:3px solid $highLight;	
}

.grid-cols {	
	display: flex;
	flex-flow: row wrap;
	justify-content: start;
}

.grid-auto-col {
	padding: 0 40px;

	&:first-child {
		padding-left: 0;
	}
}

.bordered-stage {
	border:3px solid $highLight;
}

/***************************************************
HOME PAGE STYLES
***************************************************/
#home-slides {	

	.hotel-label {
		background: $bodyTxt;
		bottom: 5%;
		color: white;
		padding: 20px;
		position: absolute;
		right: 10%;
		width: 447px;

		p {
			margin: 0;
		}

		.btn {
			position:absolute;
			top: 20px;
			right: -40px;
		}
	}
}

/*********************************
Media Queries Desktop first
********************************/
 /* Medium Devices, ipad landscape */
@media only screen and (max-width : 1024px) {
	// hotel page
	#hotel-info {
		.chevron {
			display: block;
		}

		.hotel-content-slides {
			width: 100%;
		}

		.hotel-content-description {
			min-height: auto;

			nav,h3,p {
				display: none;
			}
		}

		.btn {
			bottom: auto;
			top: -40px;
			right: 0;
		}
	}
}

/* Small Devices, tablets portrait */
@media only screen and (max-width : 768px) {
	// Hotel page
	#hotel-info {
		.chevron {
			display: none;
		}

		.hotel-content-slides {
			height: 50%;
		}

		.hotel-content-description {
			height: 100%;
			left: 0;
			position: relative;
			width: 100%;

			nav,h3,p {
				display: block;
			}

			.btn {
				margin: 0;
				position:relative;
				top: auto;
			}
		}
	}
}

/* Mobile portrait */
@media only screen and (max-width : 480px) {
	#home-slides {
		.hotel-label {
			background: $bodyTxt;
			color: white;			
			bottom: 0;
			padding: 20px;
			position: absolute;
			right: 0;
			width: 100%;

			p {
				margin: 0;
			}

			.btn {
				right: 0;
				top: 0;
			}
		}
	}

	// hotel page
	#hotel-info {
		nav {
			ul {
				li {
					a {
						margin-right: 10px;
					}
				}
			}
		}
	}
	// Seriously WTF is that ^^^^^ ??

	.container {
		margin: 0 4%;
	}

	.grid-cols {
		flex-flow: column wrap;
	}

	.grid-auto-col {
		padding-left: 0;

		ul {
			display: none;

			&.show-city {
				display: block;
			}
		}

	}
}