body {	
	font-family: Muli;
	font-size: 1em;
	font-style: normal;
	font-weight: 400;
}

a{
	text-decoration:none;
	transition:all 0.3s ease-in-out;
}

p {
   font-family: Muli;
   font-size: 1em;
   font-style: normal;
   font-weight: 400;
   line-height: 1.5em;
   margin: 0px 0px 0.88em 0px;
}

p.intro {
   border-bottom: 1px;
   border-top: 1px;
   border-style: solid;
   font-size: 1.250em;
   line-height: 1.5em;
   margin: 1.038em 0px;
   padding: 1.154em;
   text-align: center;
}

h1,h2,h3,h4,h5,h6{
   font-family: "Julius Sans One";
   font-weight: 400;
}

h1 {
   font-size: 2.500em;
   letter-spacing: 0.05em;
   line-height: 1.2em;
   margin: 0px;
   text-transform: uppercase;
}

h2 {
   font-size: 1.875em;
   line-height: 1.2em;
   letter-spacing: 0.05em;
   margin: 0;
}

h3 {
   font-size: 1.625em;
   letter-spacing: 0.05em;
   line-height: 0.931em;
   margin: 1em 0px 0.5em 0px;
}
h4 {
   font-size: 1.375em;
   line-height: 0.931em;
   margin: 1em 0px 0.5em 0px;
}

p.pullquote {
   border-style: solid;
   border-left-width: 3px;
   font-size: 0.4em;
   line-height: 1.421em;
   margin: 0px 0px 1.421em 0px;
   padding-left: 10px;
}

p.author {
   font-size: 13px;
   letter-spacing: 2px;
   margin-top: 27px;
   text-align: center;
   text-transform: uppercase;
}

.script-subtitle {
  color: rgba(199, 196, 156, .45);
  display: block;
  font-family: 'Mr De Haviland', cursive;
}

h1 {
  .script-subtitle {
    font-size: 2.5em;
  }
}

h3 {
  .script-subtitle {
    font-size: 3.5em;
  }
}

// lists
ul{
	margin: 0 0 1.5em 0;
	padding: 0;

  li {
    line-height: 1.5;
    margin-bottom: .768em;
    margin-left: 1em;
  }
}

// navigation
header.header{  
  ul{
    margin-bottom: 0;

		li{
			font-family: "Julius Sans One";
			font-size: 1.125em;
		}
  }
  h3{
  	margin: 0;
  	padding: 0;
  }
}


/*********************************
Media Queries Desktop first
********************************/
 /* Medium Devices, ipad landscape */
@media only screen and (max-width : 1200px) {
	body {	
		font-size: 13px;
	}

  .hotel-amenities .amenities-info li, p{
    font-size: 1.125em;
  }
}

/* Small Devices, tablets portrait */
@media only screen and (max-width : 768px) {
}

/* Mobile portrait */
@media only screen and (max-width : 480px) {

}