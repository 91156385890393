 .brand-text, a {
 	color: $brandColor;
 }

 .high-light-text {
 	color: $highLight;
 }

 .light-gray-back {
 	background: $almost-white;
 }

 .clearfix:before,
.clearfix:after {
    content: ".";    
    display: block;    
    height: 0;    
    overflow: hidden; 
}
.clearfix:after { clear: both; }
.clearfix { zoom: 1; } /* IE < 8 */