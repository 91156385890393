/***************************************************
Destinations page
***************************************************/
.destinations {
	background: $almost-white;	

	.destination-title {
		bottom: 0;
		left: -11%;
		position: absolute;
		width: 30%;
	}

	.destinations-map {
		left: 50%;
		position: absolute;
		transform: translateX(-50%);
		width: 78%;

		.world-map {
			width: 100%;
			z-index: 1;
		}

		.map-pin {
			background: transparent;
			border: 0;
			cursor: pointer;
			padding: 0;
			position: absolute;
			visibility: hidden;
			transform: translateY(-150px);
			transition: all .4s ease-in;
			z-index: 2;

			&:hover {
				opacity: .85;
			}

			img {
				display: block;
				width: 100%;
			}
		}

		// pin positions
		#canada {
			left: 15%;
			top: 25%;
		}

		#usa {
			left: 17%;
			top: 40%;
		}

		#mexico {
			left: 14%;
			top: 49%;
		}

		#caribbean {
			left: 22%;
			top: 52%;
		}

		#central-america {
			left: 17%;
			top: 54%;
		}

		#south-america {
			left: 25%;
			top: 65%;
		}

		#europe {
			left: 46%;
			top: 35%;
		}

		#africa {
			left: 50%;
			top: 60%;
		}

		#asia {
			left: 70%;
			top: 35%;
		}

		#oceania {
			left: 85%;
			top: 75%;
		}

		#united-kingdom {
			left: 41%;
			top: 30%;
		}

	}

	// Destinations box
	.destinations-list {
		@include box-shadow(0, 3px, 33px, rgba(0, 0, 0, .35));
		background: $darkGray;
		display: none;
		height: 65vh;
		position: absolute;
		right: 0;
		top: 0;
		width: 30%;
		z-index: 3;

		.region {
			display: none;
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			padding: 40px;
		}

		.close-destinations {
			position: absolute;
			right: 0;
			top: 0;
			transform: translate(20%, -50%);
			z-index: 10;
		}
	}

	ul {
		list-style: none inside;
	}

	li {
		margin-bottom: .675em;

		a {
			color: white;
			display: block;
		}
	}

	h3,
	li {
		opacity: 0;
		transform: translateX(50%);
		transition: all .5s ease-in-out;
		// transition-delay: .2s;
	}

	// when calling the destination list pop up
	.show-destination {
		// display: block;

		h3,
		li {
			opacity: 1;
			transform: translateX(0);
		}
	}
}

.region::-webkit-scrollbar {
	margin-right: 10px;
	width: 1em;
}

.region::-webkit-scrollbar-track {
	padding: 10px;
}

.region::-webkit-scrollbar-thumb {
	background-color: $highLight;
	border: 4px solid $darkGray;
	border-radius: 25px;
}

.region-coming-soon {
	text-align: center;
}

/*********************************
Media Queries Desktop first
********************************/
 /* Medium Devices, ipad landscape */
@media only screen and (max-width : 1024px) {
	.destinations {
		.destinations-map {
			.map-pin {
				height: 35px;
				width: 35px;
			}
		}

		.destinations-list {
			.region {
				padding: 40px 20px;
			}
		}
	}
}
/* Small Devices, tablets portrait */
@media only screen and (max-width : 768px) {
	.destinations {
		background: transparent;

		.destination-title {
			bottom: auto;
			left: 0;
			text-align: center;
			width: 100%;
		}

		.destinations-map {
			.world-map,
			.map-pin {
				display: none;
			}
		}

		.destinations-list {
			background: transparent;
			box-shadow: none;
			display: block;
			height: auto;
			position: relative;
			right: auto;
			width: auto;

			.close-destinations {
				display: none;
			}

			.region {
				border-top: 1px solid $brandColor;;
				display: flex;
				flex-flow: row wrap;
				padding: 40px 0;

				h2 {
					width: 100%;
				}

				.country-state {
					width: 33.333%;
				}
			}
			.close-destinations + .region {
				border: 0;
				padding-top: 60px;
			}
		}

		h3,
		li {
			opacity: 1;
			transform: translateX(0);
		}

		li {
			a {
				color: $darkGray;
			}
		}
	}
}
/* Mobile portrait */
@media only screen and (max-width : 480px) {
	.destinations {
		padding-top: 80px;

		.destinations-list {
			.region {
				flex-flow: column wrap;

				.country-state {
					width: 100%;
					display: none;

					&.show-city {
						display: block;
					}
				}
			}

			.close-destinations + .region {
				padding-top: 80px;
			}
		}
	}
}