// buttons
.btn {
	@include box-shadow(0, 3px, 6px, rgba(0, 0, 0, .38), false);
	background-color: $brandColor;
	border: none;
	border-radius: 25px 0 25px 0;
	color: white;
	cursor: pointer;
	display: inline-block;
	font-family: Muli;
	letter-spacing: 0.065em;
	line-height: 3;
	padding: 0 20px;
	text-transform: uppercase;
	transition: all 0.3s ease-in-out;

	&:hover {
		background: shade($brandColor, 40%)
	}
}

// input fields
.input-field {
	border: 0;
	border-bottom: 1px solid $highLight;
	display: block; 
	margin-bottom: 1.5em;
	width: 100%;
}

label {
	display: block;
	font-size: 0.750em;
	margin-bottom: .7em;
}