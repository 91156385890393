@charset "UTF-8";
/*!
Theme Name: Small Chic Hotels Theme
Theme URI: http://www.smallchichotels.com/
Author: Jorge Elvir
Description: Theme for the small chic hotels website
Version: 1.1
*/
// variables
@import "components/variables";

// components
@import "components/normalize";
@import "components/typography";
@import "components/grid";
@import "components/navigation";
@import "components/flexslider";
@import "components/modals";


// forms and buttons
@import "components/forms";

// Helper Classes
@import "components/helpers";

// pages
@import "components/destinations";
@import "components/city";
@import "components/hotel-page";
@import "components/form-pages";
