 //  Colors
$bodyTxt: #62676A;
$brandColor: #7C181A;
$highLight:#C7C49C;
$lightGray: #ABAA97;
$darkGray: #191919;
$almost-white: #F7F7F5;

 // mixins
 @mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

/// Slightly lighten a color
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

// chevrons
.chevron{
	display: block;
	width: 30px;
	height: 41px;
	background:url(images/chevron.png) white no-repeat center center;
	cursor:pointer;

	&.up{
		transform:rotate(-90deg);
	}
	&.down{
		transform:rotate(90deg);
	}
	&.left{
		transform:rotate(180deg);
	}
}