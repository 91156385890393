.city-results {	

	.grid-cols {		
		padding-top: 20px;

		> .hotel-name {
			align-items: center;
			display: flex;
    	flex-flow: column;
			margin-bottom: 20px;
			margin-right: .37%;
			position: relative;
			visibility: hidden;
			width: 32.777%;

			&:last-child {
				margin-left: 0;
			}

			.city-hotel-img {
				background-position: center center;
				background-size: cover;
				display: block;
				height: 100%;
				min-height: 300px;
				overflow: hidden;
				object-fit: cover;
				position: relative;
				width: 100%;
			}

			img {
				height: auto;
				left: 50%;
				object-fit: cover;
				min-height: 100%;
				min-width: 100%;
				position: absolute;
				transition: all .3s ease-in-out;
				transform: translate(-50%, -50%);
				top: 50%;
				width: 100%;
			}			
		}

		.btn {
			opacity: 1;
    	transform: translateY(-50%);
    	transition: all .3s ease-in-out;
		}

		h3 {
			margin: 0 0 .2em;
		}
	}
}

/*********************************
Media Queries Desktop first
********************************/
 /* Medium Devices, ipad landscape */
@media only screen and (max-width : 1024px) {
	.city-results{
		.grid-cols{
			> .hotel-name {
				margin-bottom: 40px;
			}
			.btn {
				top: 57%;				
			}
		}
	}
}

/* Small Devices, tablets portrait */
@media only screen and (max-width : 768px) {
.city-results {
		.grid-cols {
			> .hotel-name {
				width: 49%;
			}
		} 
	}
}

/* Mobile portrait */
@media only screen and (max-width : 480px) {	
	.city-results {
		padding-top: 80px;

		.grid-cols {
			> .hotel-name {
				width: 100%;
			}
		}
	}
}
